import { default as indexHV31GHddjNMeta } from "/builds/devnet.kz/website/devnet.kz/v4/src/pages/index.vue?macro=true";
export default [
  {
    name: indexHV31GHddjNMeta?.name ?? "index",
    path: indexHV31GHddjNMeta?.path ?? "/",
    meta: indexHV31GHddjNMeta || {},
    alias: indexHV31GHddjNMeta?.alias || [],
    redirect: indexHV31GHddjNMeta?.redirect,
    component: () => import("/builds/devnet.kz/website/devnet.kz/v4/src/pages/index.vue").then(m => m.default || m)
  }
]